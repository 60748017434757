form {
  background-color: rgb(243 243 247);
  height: 500px;
  width: 400px;
  margin: 0 auto;
  margin-top: 40px;
  border-radius: 5%;
  padding: 50px;
  -webkit-box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
  box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
}
.authenForms {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50%;
}
.authenForms input {
  border: 1px solid rgba(225, 225, 225, 0.85);
  border-radius: 9999px;
  padding: 10px;
  height: 40px;
  width: 300px;
  -webkit-box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2) inset;
  -moz-box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2) inset;
  box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2) inset;
}
.authenForms input::placeholder {
  font-size: 11px;
  letter-spacing: 2px;
}
.authenButtons {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.authenButtons button {
  background-color: var(--main-color);
  border: 2px solid var(--main-color);
  width: 300px;
  border-radius: 9999px;
  color: #fff;
  margin-top: 20px;
  transition: all 0.5s ease;
  -webkit-box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
  box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
}
.authenButtons button:hover {
  color: rgb(244, 186, 148);
}
.authenButtons button:active {
  transform: translateY(4px);
}
.signAndPass {
  display: flex;
  margin-top: 20px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  cursor: pointer;
}

@media (max-width: 1090px) {
  form {
    background-color: rgb(243 243 247);
    height: 270px;
    width: 300px;
    margin-top: 50px;
    padding: 50px;
  }
  .authenForms input {
    width: 200px;
    height: 20px;
  }
  .authenForms input::placeholder {
    font-size: 8px;
    letter-spacing: 1px;
  }
  .authenButtons button {
    width: 200px;
    font-size: 10px;
  }
  .signAndPass {
    margin-top: 5px;
    font-size: 8px;
  }
}
