:root {
  --background-color: rgb(255, 240, 230);
}

.pizzaList {
  margin-top: 10px;
  margin-left: 5%;
  margin-right: 5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.pizzaCard {
  height: 500px;
  width: 300px;
  padding: 20px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.pizzaCard img {
  cursor: pointer;
  transition: transform 0.3s ease;
  transform: translateY(0px);
}
.pizzaCard img:hover {
  transform: translateY(5px);
}

.pizzaCard h3 {
  margin: 15px 0px;
  width: 100%;
  color: rgb(0, 0, 0);
  font-size: 20px;
  text-align: start;
  line-height: 24px;
  font-family: system-ui, 'Segoe UI', 'Helvetica Neue', Arial, sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  font-weight: 500;
}

.pizzaCard p {
  font-size: 14px;
  line-height: 20px;
  font-family: system-ui, 'Segoe UI', 'Helvetica Neue', Arial, sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  color: rgb(92, 99, 112);
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
}
.pizzaCard__footer {
  margin: 15px 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pizzaCard__price {
  padding-right: 4px;
  font-size: 20px;
  color: rgb(0, 0, 0);
  font-family: system-ui, 'Segoe UI', 'Helvetica Neue', Arial, sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  font-weight: 600;
  line-height: 24px;
}
.pizzaCard__footer Button {
  height: 40px;
  min-width: 120px;
  padding: 8px 20px;
  font-size: 16px;
  line-height: 24px;
  background-color: rgb(255, 240, 230);
  color: rgb(209, 87, 0);
  outline: none;
  border: none;
  border-radius: 9999px;
  text-align: center;
  font-family: system-ui, 'Segoe UI', 'Helvetica Neue', Arial, sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  font-weight: 500;
  text-decoration: none;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  user-select: none;
  transition-property: background-color, color;
  transition-duration: 200ms;
  transition-timing-function: ease-out;
}
.pizzaCard__footer Button:active {
  background-color: rgb(255, 240, 230) !important;
  color: rgb(209, 87, 0) !important;
  border: none !important;
  transform: translateY(2px);
}
.pizzaCard__footer button:hover {
  background-color: rgb(244, 193, 157);
}

/*__________________Modal style______________*/

.modalStyle {
  height: 400px !important;
  display: flex;
}

.modalBodyContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.orderItem {
  display: flex;
  align-items: center;
}

.orderItem_options {
  padding: 20px 20px;
}
.picca_size,
.doughThickness {
  border-radius: 9999px;
  background-color: rgb(243, 243, 247);
  height: 32px;
  display: flex;
  overflow: hidden;
  margin: 8px 0px;
}

label {
  border-radius: 9999px;
  flex: 0%;
  cursor: pointer;
  color: rgb(0, 0, 0);
  font-size: 12px;
  line-height: 32px;
  font-family: system-ui, 'Segoe UI', 'Helvetica Neue', Arial, sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  font-weight: normal;
  text-align: center;
  height: 32px;
  transition: color 150ms ease 0s;
}

label:hover {
  background-color: #fff;
  height: 29px;
  margin: 2px;
  box-shadow: -2px 1px 20px -8px rgba(0, 0, 0, 0.73);
}

.orderItem_options input {
  display: none;
}

.focusBtn {
  background-color: #fff;
  height: 29px;
  margin: 2px;
  box-shadow: -2px 1px 20px -8px rgba(0, 0, 0, 0.73);
}
.btnOrderToCart {
  padding: 10px;
  margin-top: 50px;
  background-color: rgb(255, 105, 0);
  border: 5px solid rgba(7, 3, 0, 0);
  border-radius: 9999px;
  width: 400px;
  height: 48px;
  font-size: 16px;
}
.btnOrderToCart:hover {
  background-color: rgb(247, 167, 114);
  border: 5px solid rgba(7, 3, 0, 0);
}

.btnOrderToCart:active {
  background-color: rgb(247, 167, 114) !important;
  border: 5px solid rgba(7, 3, 0, 0) !important;
  transform: translateY(2px);
}
@media (max-width: 1090px) {
  /* .pizzaList {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  } */

  .modalStyle {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 250px !important;
  }
  .orderItemProduct {
    display: none;
  }

  .orderItemText {
    font-size: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0;
  }

  .orderItemText p {
    font-size: 8px;
  }
  .btnOrderToCart {
    display: flex;
    justify-content: center;
    line-height: 8px;
    padding: 10px;
    margin-top: 5px;
    margin: 0 auto;
    width: 50%;
    height: 15%;
    font-size: 8px;
  }
}
@media (max-width: 500px) {
  .pizzaCard {
    height: 200px;
    width: 300px;
    /* padding: 20px; */
    /* overflow: hidden; */
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 10px;
  }
  .pizzaCard img {
    width: 40%;
  }
  .pizzaCard h3 {
    margin: 2px 0px;
    width: 100%;
    color: rgb(0, 0, 0);
    font-size: 15px;
    text-align: start;
    line-height: 24px;
    font-family: system-ui, 'Segoe UI', 'Helvetica Neue', Arial, sans-serif,
      'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
    font-weight: 500;
  }
  .pizzaCard p {
    font-size: 10px;
  }
  .pizzaCard span {
    font-size: 12px;
  }
  .pizzaCard__footer Button {
    height: 20px;
    min-width: 40px;
    padding: 8px 20px;
    font-size: 10px;
    line-height: 5px;
  }
}
