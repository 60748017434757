* {
  font-family: 'Poppins', sans-serif;
  text-decoration: none;
  list-style: none;
}

:root {
  --text-color: #222327;
  --main-color: rgb(255, 105, 0);
  --background-color: rgb(243, 243, 247);
  --background-color-item: #fff;
}
/*----------------Upper line navbar-----------------*/
.infoHeader {
  width: 100%;
  height: 80px;
  background-color: rgba(243, 243, 247, 0.85);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.textInfoHeader {
  display: flex;
  justify-content: flex-start;
}
.textInfoHeader p {
  font-size: 15px;
  color: var(--text-color);
  margin: 1rem;
}

.authenInfoHeader {
  display: flex;
  justify-content: flex-end;
}
.bestTimeDelivery {
  color: rgb(255, 105, 0) !important;
}

/*----------------main line navbar-----------------*/
header {
  position: sticky;
  width: 100%;
  height: 30px;
  top: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: transparent;
  transition: all 0.5s ease;
  padding: 2rem 5%;
  background-color: rgba(243, 243, 247, 0.85);
}
.logo {
  display: flex;
  align-items: center;
  color: var(--main-color);
  font-size: 2rem;
}
.logo__icon {
  font-size: 2.5rem;
}
.logo span {
  color: var(--text-color);
  cursor: pointer;
}

.navbar {
  display: flex;
}

.navbar a {
  color: var(--text-color);
  font-size: 1.1rem;
  font-weight: 500;
  padding: 5px 0;
  margin: 0 30px;
  transition: all 0.5s ease;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;
}
.iconMenu {
  font-size: 1.1rem;
  font-weight: 500;
  margin-right: 4px;
}

.navbar a:hover {
  color: var(--main-color);
}

.navbar a:hover .iconMenu {
  color: var(--main-color);
}

.ifCartEmpty {
  padding-top: 50%;
  padding-bottom: 20%;
  background-color: #fff;
}
/*----------------------------------------------*/

/*----------------Login-----------------*/
.login {
  margin-right: 5%;
}
.login a {
  font-size: 1.7rem;
  color: var(--main-color) !important;
}

.login a:hover {
  color: rgb(244, 186, 148) !important;
}

/*----------------------------------------------*/
/*-------------------Cart style------------------*/

.cart {
  background-color: rgba(243, 243, 247, 0.85);
  position: absolute;
  transition: all 0.5s ease;
  top: 100%;
  width: 350px;
  height: 100vh;
  right: 100%;
  z-index: 5;
  text-align: center;
}

.cartScroll {
  overflow: scroll;
  height: 75vh;
  background-color: rgba(243, 243, 247, 0.85);
}
.cartIconBtn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 9999px;
  width: 110px;
  background-color: var(--main-color);
  font-size: 20px;
  line-height: 35px;
  transition: all 0.5s ease;
  color: var(--background-color-item);
  text-decoration: none;
  padding-left: 10%;
  padding-right: 10%;
  border: 5px solid rgba(7, 3, 0, 0);
}
.cartIconBtn:hover {
  color: rgb(244, 186, 148);
}

.cartIconBtnNull {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background-color: var(--main-color);
  font-size: 20px;
  line-height: 35px;
  transition: all 0.5s ease;
  color: var(--background-color-item);
  text-decoration: none;
  padding-left: 10%;
  padding-right: 10%;
  border: 5px solid rgba(7, 3, 0, 0);
}

.cartIconBtnNull:hover {
  color: rgb(244, 186, 148);
}

.cartIconBtn:active {
  transform: translateY(2px);
}
.cartCountNull {
  display: none;
}

.iteminCartWraper {
  display: flex;
  border: 1px solid var(--main-color);
  border: none;
  margin: 1px;
  padding: 8px;
  border-radius: 5%;
  background-color: var(--background-color-item);
}

.iteminCartWraper:first-child {
  margin: 0;
  padding-top: 15px;
}

.itemInCartTextInfo {
  text-align: left;
  padding-left: 15px;
  font-size: 16px;
  letter-spacing: 2px;
  line-height: 16px;
}

.itemInCartTextInfo p {
  color: rgb(92, 99, 112);
  letter-spacing: 0px;
  font-size: 12px;
}
.iteminCartCount {
  background-color: var(--background-color);
  color: rgb(0, 0, 0);
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 32px;
  width: 96px;
  padding: 4px;
  border-radius: 9999px;
  font-size: 16px;
  line-height: 1.4;
  font-weight: 400;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', Arial, sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}

.iteminCartInfo {
  width: 100%;
}
.iteminCartPriceInfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
}
.iconForCount {
  cursor: pointer;
}

.iconMinus {
  color: #d11a2a;
}
.CiCircleRemove {
  font-size: 40px;
  cursor: pointer;
  padding-right: 8px;
}

.CiCircleRemove:hover {
  color: #d11a2a;
  transition: all 0.1s ease;
}
.open {
  right: 0%;
}
.closeCartBtn {
  padding: 0;
  border: none;
  background: none;
  color: tomato;
  background-color: #d11a2a;
  width: 100%;
}
.closeCartBtn:hover {
  color: #ffff;
}

::-webkit-scrollbar {
  background-color: rgba(243, 243, 247, 0.85);

  width: 7px;
}
body::-webkit-scrollbar-thumb,
.cartScroll::-webkit-scrollbar-thumb {
  background-color: #bfbfbf;
}

/*____________Cart footer_____________*/

.costBtn {
  background-color: rgb(255, 105, 0);
  border: 5px solid rgba(7, 3, 0, 0);
  border-radius: 9999px;
  width: 250px;
  height: 50px;
  font-size: 20px;
  color: #fff;
}
/*__________________________________*/
@media (max-width: 1280px) {
  header {
    padding: 2.5rem 2%;
    transition: 0.5s;
  }
  .infoHeader {
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .textInfoHeader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .textInfoHeade p {
    line-height: 2px;
    font-size: 15px;
  }

  .navbar a {
    margin: 5px 20px;
  }
}

@media (max-width: 1090px) {
  header {
    flex-direction: column;
  }
  .handleCart {
    display: flex;
    justify-content: flex-end;
    margin-top: 1%;
    width: 100%;
  }
  .navbar {
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  ol,
  ul {
    padding-left: 0;
  }
  .navbar a {
    font-size: 0.8rem;
    padding: 5px;
    margin: 0;
  }

  .logo {
    display: none;
  }
}
