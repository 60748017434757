.drinkBody {
  height: 350px;
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.drinksCart {
  margin-left: 10%;
  margin-right: 5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.drinkItem {
  flex-basis: calc(25% - 20px);
}

.drinkBody h3 {
  margin: 15px 0px;
  margin-top: -8px;
  width: 100%;
  color: rgb(0, 0, 0);
  font-size: 20px;
  text-align: center;
  line-height: 24px;
  font-family: system-ui, 'Segoe UI', 'Helvetica Neue', Arial, sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  font-weight: 500;
}

.priceBtn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.priceBtn span {
  padding-right: 4px;
  font-size: 20px;
  color: rgb(0, 0, 0);
  font-family: system-ui, 'Segoe UI', 'Helvetica Neue', Arial, sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  font-weight: 600;
  line-height: 20px;
}
.priceBtn button {
  height: 40px;
  min-width: 120px;
  padding: 8px 20px;
  font-size: 16px;
  line-height: 24px;
  background-color: rgb(255, 240, 230);
  color: rgb(209, 87, 0);
  outline: none;
  border: none;
  border-radius: 9999px;
  text-align: center;
  font-family: system-ui, 'Segoe UI', 'Helvetica Neue', Arial, sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  font-weight: 500;
  text-decoration: none;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  user-select: none;
  transition-property: background-color, color;
  transition-duration: 200ms;
  transition-timing-function: ease-out;
}

.priceBtn button:hover {
  background-color: rgb(244, 193, 157);
}
.priceBtn button:active {
  background-color: rgb(255, 240, 230) !important;
  color: rgb(209, 87, 0) !important;
  border: none !important;
  transform: translateY(2px);
}

@media (max-width: 1090px) {
}
