* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  background-color: rgb(255, 255, 255);
  min-height: 100vh;
}
.active {
  color: rgb(255, 105, 0) !important;
  text-decoration: none;
}
