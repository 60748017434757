.content {
  min-height: calc(50vh);
}
.footer {
  height: 580px;
  background-color: rgb(24, 24, 24);
  width: 100%;
  position: relative;
}
.orangeLine {
  height: 60px;
  background-color: rgb(255, 105, 0);
  display: flex;
  align-items: center;
  justify-content: center;
}

.orangeLine p {
  color: rgb(255, 255, 255);
  font-size: 18px;
  padding: 10px;
}

.row {
  display: flex;
  align-items: center;
  color: #646771;
  padding: 5%;
}
.title {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.row h1,
.row h5 {
  color: rgb(189, 196, 203);
}
.col_row_1 {
  flex-basis: 30%;
}

.col_row_2 {
  flex-basis: 70%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.col {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.col ul {
  margin-right: 15px;
  margin-left: 15px;
  text-align: center;
  padding: 0;
}

hr {
  background-color: #646771;
  height: 5px;
  width: 90%;
  margin: 0 auto;
}
@media (max-width: 1090px) {
  .orangeLine p {
    font-size: 12px;
  }
  .row {
    display: flex;
    flex-direction: column;
  }
  .col h5 {
    font-size: 12px;
  }

  .col ul li {
    font-size: 8px;
  }
}
